.portfolio-page {

  .project-element {
    margin-top: 5em;

    &:first-child {
      margin-top: 1em;
    }
  }


  h4 {
    color: #fff;
    font-size: 32px;
    line-height: 18px;
    margin-bottom: 20px;
    font-family: 'Coolvetica';
    font-weight: 200;
    letter-spacing: 2px;
  }

  p {
    color: #fff;
    margin-bottom: 3px;
    font-weight: 400;
    font-size: 16px;
    font-family: sans-serif;
  } 

  
  a {
    font-size: 20px;
      color: #fff;
      line-height: 51px;
      height: 51px;
      position: relative;

      &:hover {
        color: #ffd700;

        &:after {
          opacity: 1;
        }
      }
  }


  

  .project-link {
    color: #ffd700;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 1px 9px;
    border: 1px solid #ffd700;
    white-space: nowrap;
    margin-bottom: 25px;

    &:hover {
      background: #ffd700;
      color: #333;
    }
  }

  .images-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .images-container img {
    flex: 1 1 auto;
    max-width: 22%;
    margin: 5px;
    
  }

  .images-container img:only-child {
    flex: 1 0 100%; /* Takes full width of the container */
    max-width: 800px; /* Overrides any max-width set previously */
  }

  @media (max-width: 600px) { /* Adjust breakpoint as needed */
    .container {
      flex-direction: column;
    }
  
    .container img {
      max-width: 100%; /* Full width on smaller screens */
    }
  }
}