.nav-bar {
  background: #181818;
  width: 60px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;

  .logo {
    display: block;
    padding: 8px 0;

    img {
      display: block;
      margin: 8px auto;
      width: 24px;
      height: auto;

      &.sub-logo {
        width: 50px;
      }
    }
  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -120px;
    width: 100%;

    a {
      font-size: 22px;
      color: #fff;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #ffd700;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &:after {
          content: 'HOME';
        }
      }
    }

    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }

    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }

    a.projects-link {
      &:after {
        content: 'PROJECTS';
      }
    }

    a.active {
      svg {
        color: #ffd700;
      }
    }
  }

  ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 7px 0;
        display: block;
        font-size: 20px;
        line-height: 20px;
        color: #fff;
      }

      &:hover {
        .anchor-icon {
          color: #ffd700;
        }
      }

      svg {
        color: #fff;
        height: 20px;
      }
    }
  }
}

.hamburger-icon, .close-icon {
  display: none;
}


@media screen and (max-width: 640px) {
  .nav-bar {
    width: 40px;
  }
}
//   .nav-bar { 
//     position: initial;
//     height: auto;
//     min-height: auto;

//      ul, nav {
//       display: none;
//      }

//      nav {
//       width: 100%;
//       top: 0;
//       left: 0;
//       background: #181818;
//       height: 100%;
//       margin: 0;
//       z-index: 2;
//       position: fixed;

//       &.mobile-show {
//         display: block;
//       }

//       a {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//       }

//       a:after {
//         opacity: 1;
//         position: initial;
//         width: auto;
//         margin-left: 10PX;
//       }

//       a svg {
//         opacity: 1 !important;
//       }
//      }

//      .hamburger-icon,
//      .close-icon {
//         position: absolute;
//         top: 15px;
//         right: 15px;
//         display: block;
//      }
//   }
// }