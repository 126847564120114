.home-page {
  .text-zone {
    position: absolute;
    left: 12%;
    top: 50%;
    transform: translateY(-50%);
    width:  40%;
    max-height: 90%;
  }

  .projects {
    position: absolute;
    right: 15%;
    top: 2%;

    
    &:before {
      content: '<div>';
      font-family: 'La Belle Aurore';
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -30px;;
      left: -20px;
      opacity: 0.6;
    }


    &:after {
      content: '</div>';
      font-family: 'La Belle Aurore';
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -20px;
      margin-left: -20px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }

    h3 {
      color: #fff;
      font-size: 32px;
      line-height: 28px;
      margin-bottom: 20px;
      margin-left: -20px;
      font-family: 'Coolvetica';
      font-weight: 200;
      letter-spacing: 2px;
      animation: fadeIn 1s 1.5s backwards;
    }

    h4{
      color: #fff;
      font-size: 20px;
      line-height: 18px;
      margin-bottom: 5px;
      font-family: 'Coolvetica';
      font-weight: 200;
      letter-spacing: 2px;
      animation: fadeIn 1s 1.7s backwards;
    }

    p{
      color: #8d8d8d;
      margin-bottom: 3px;
      font-weight: 400;
      font-size: 11px;
      font-family: sans-serif;
      letter-spacing: 2px;
      animation: fadeIn 1s 1.8s backwards;
    } 

    img {
      margin-bottom: 40px;
    }

  }


  h1 {
    color: #ffd700;
    font-size: 56px;
    line-height: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore';
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;;
      left: 15px;
      opacity: 0.6;
    }


    &:after {
      content: '</h1>';
      font-family: 'La Belle Aurore';
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }

    img {
      width: 32px;
      margin-left: 20px;
      opacity: 0;
      height: auto;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }

  h2 {
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;

  }

  .flat-button {
    color: #ffd700;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-top: 25px;
    float: left;
    animation: fateIn 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: #ffd700;
      color: #333;
    }
  }
}

@media screen and (max-width: 640px) {
  .home-page {
    .text-zone {
      margin: auto;
      left: 50px;
      // text-align: center;
      width: 70%;
    }

    h1 {
      font-size: 40px;
    }
  }
}