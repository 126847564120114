.App {
  height: 100vh;
  width: 100vw;
}

.top-tags {
  bottom: auto;
  top: 35px;
}

.tags {
  color: #ffd700;
  opacity: 0.6;
  position: absolute;
  bottom: 0;
  left: 120px;
  font-size: 18px;
  font-family: 'La Belle Aurore', cursive;
}

.bottom-tag-html {
  margin-left: -20px;
}

.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.container.contact-page,
.container.about-page,
.container.portfolio-page {
    h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      color: #ffd700;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: -10px;

      &:before {
        content: '<h1>';
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }

      &:after {
        content: '<h1/>';
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }
    }

  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    max-width: 80%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}

@media screen and (min-width: 640px) {
  .page {
    height: 100%;
    flex-grow: 1;
  }
}

@media screen and (max-width: 640px) {
  .page {
    position: initial;
  }

  h1 {
    font-size: 50px;
  }

 

  // .container {
  //   position: absolute;
  //   height: auto;

  //   &.contact-page,
  //   &.about-page,
  //   &.portfolio-page {
  //     .text-zone {
  //       position: absolute;
  //       left: 10%;
  //       top: 50%;
  //       transform: translateY(-50%);
  //       width: 80%;
  //       vertical-align: middle;
  //       display: table-cell;
  //       max-height: 90%;
  //       padding: 20px;
  //       box-sizing: border-box;
  //       overflow: scroll;
  //     }
  //   }

  //   // &.portfolio-page {
  //   //   width: 100%;
  //   //   padding: 20px;
  //   //   box-sizing: border-box;

  //   //   h1.page-title {
  //   //     margin-left: 20px;
  //   //     margin-top: 20px;
  //   //   }

  //   //   .image-box {
  //   //     height: 200px;
  //   //     max-width: calc(50% - 10px);
  //   //   }
  //   // }
  // }

  .stage-cube-cont {
    position: initial;
    width: 100%;
    height: 0%;
    overflow: visible;
  }
}